// OVERRIDE BOOTSTRAP VARIABLES

// use right path for glyphicons
$icon-font-path: "/font/bootstrap/";
// use right path for font-awesome
$fa-font-path: "/font/font-awesome";

// COLORS
// -----------------------------------------------------------------------------
//greys
$brand-primary: #515151;

$brand-1: #919191;
$brand-2: #E0E0E0;
$brand-3: #B5B5B5;
$brand-4: #F4F4F4;
$brand-5: #A2A2A2;

$bg-color1: #FBFBFB;

$status-green: #BAD405;
$status-orange: #ff5f28;
$status-red: #DD1D21;
$status-grey: #e0dcd5;

$sidebar-color: #f7f4ef;

$body-yellow: #FBCE07;
$body-red: #DD1D21;
$body-green: #BAD405;
$body-white: #ffffff;
$body-blue: #00acc1;

$brand-warning: $body-red;
$brand-success: $body-green;

$link-hover-color: $body-red;

// TYPOGRAPHY
// -----------------------------------------------------------------------------
// $font-family-main:        "Futura LT W01 Book";
// $font-family-main-bold:   "Futura LT W01 Bold";
// $font-family-sans-serif:  "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
// $font-family-serif:       Georgia, "Times New Roman", Times, serif !default;
// $font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-main:          Arial, Roboto, Helvetica, sans-serif;
$font-family-main-bold:     $font-family-main;
$font-family-sans-serif:    $font-family-main;
$font-family-serif:         $font-family-main;
$font-family-monospace:     $font-family-main;
$font-family-base:          $font-family-sans-serif !default;

$font-size-base:          14px !default;
$font-size-large:         ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-h1:            floor(($font-size-base * 2)) !default; // ~36px
$font-size-h2:            floor(($font-size-base * 1.75)) !default; // ~30px
$font-size-h3:            ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4:            ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5:            ceil(($font-size-base * 1.15)) !default;
$font-size-h6:            ceil(($font-size-base * 0.85)) !default; // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family:    inherit !default;
$headings-font-weight:    500 !default;
$headings-line-height:    1.1 !default;
$headings-color:          inherit !default;

// BORDERS
// -----------------------------------------------------------------------------
$border-radius-base: 0px;

// NAVBAR
// -----------------------------------------------------------------------------
$navbar-default-link-color:                 #fff;
$navbar-default-link-hover-color:           darken($brand-primary, 25%);
$navbar-default-link-active-color:          darken($brand-primary, 25%);
$navbar-default-link-active-bg:             transparent !default;
$navbar-default-link-disabled-color:        #ccc !default;
$navbar-default-link-disabled-bg:           transparent !default;

// DROPDOWNS
// -----------------------------------------------------------------------------
//$dropdown-bg:                    #f5f6f7 !default;
//$dropdown-border:                rgba(0,0,0,.15) !default;
//$dropdown-fallback-border:       #ccc !default;
//$dropdown-divider-bg:            #e5e5e5 !default;
//$dropdown-link-color:            #000 !default;
$dropdown-link-hover-color:      $brand-primary;
$dropdown-link-hover-bg:         transparent !default;
//$dropdown-link-active-color:     $component-active-color !default;
//$dropdown-link-active-bg:        $component-active-bg !default;
//$dropdown-link-disabled-color:   $gray-light !default;
//$dropdown-header-color:          $gray-light !default;
//$dropdown-caret-color:           #000 !default;

// FONTS
// -----------------------------------------------------------------------------
$font-family-sans-serif:  "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:       "Lato",   Georgia, "Times New Roman", Times, serif;
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base:        $font-family-sans-serif;

//== Panels
//
//##

$panel-heading-padding: 1.75rem 5.75rem 1.75rem 0;

$input-group-addon-border-color: $brand-1;

$hr-border: #ccc;

$grid-float-breakpoint: 0;