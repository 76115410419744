// Modals
// ----------------------------------------------------------------------------

.modal {
  .modal-content {
    border-radius: 0px;
  }
  .modal-header {
    border: none;
  }
  .modal-footer {
    .col-md-6:first-child {
      padding-right: 5px;
    }
    .col-md-6:last-child {
      padding-left: 5px;
    }
    border: none;
  }
}
