@media only screen and (max-width: 767px) {
  nav.navbar.navbar-main {
    ul {
      li {
        border: none;
        a {
          line-height: 28px;
          text-align: left;
        }
      }
      li:last-child {
        border: none;
      }
    }
    .navbar-brand {
      line-height: 36px;
    }
  }
}