// Alerts
// ----------------------------------------------------------------------------

.alert {
  border-radius: 0px !important;
  background-color: $brand-4;
  font-family: $font-family-main-bold;
  color: $brand-5;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
}
.alert-warning {
  background-color: $body-yellow;
  color: $brand-primary;
}

.alert-success {
  background-color: $body-green;
  color: $brand-primary;
}

.alert-danger {
  background-color: $body-red;
  color: $body-white;
}
