// Bordered box
// ----------------------------------------------------------------------------

.bordered-box {
  padding: 20px;
  border: 2px solid $brand-primary;
  margin-bottom: 10px;
  h1 {
    color: $body-red;
    margin-bottom: 10px;
  }
  h2 {
    color: $brand-primary;
    margin-bottom: 10px;
  }
  hr {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 0px;
    padding-right: 0px;
    border-top: 2px solid $brand-primary;
  }
}
