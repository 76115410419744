// Labels
// ----------------------------------------------------------------------------

.label-default {
  background-color: $brand-primary;
  color: $brand-2;
}
.label-info {
  background-color: $body-blue;
  color: $brand-primary;
}
.label-success {
  background-color: $body-green;
  color: $brand-primary;
}
.label-warning {
  background-color: $body-yellow;
  color: $brand-primary;
}
.label-danger {
  background-color: $body-red;
  color: $body-white;
}
.label-primary {
  background-color: $body-blue;
  color: $body-white;
}
