//FORMS
//-------------------------------------------------------------------------------
form {
  margin-bottom: 10px;
  label {
    color: $brand-primary;
  }
  .control-label {
    display: block;
  }
  .form-control {
    border: 1px solid $brand-1;
    border-radius: 0px;
    background-color: $bg-color1;
    height: 45px;
  }
  .has-error {
    .help-block {
      color: $body-red;
    }
    .help-block-error {
      padding: 5px;
      background-color: $body-red;
      color: $body-white;
    }
    .control-label {
      color: $body-red;
    }
  }
  .slider-box {
    text-align: center;
    .slider {
      width: 80%;
      margin-left: 10px;
      margin-right: 10px;
      display: inline-block;
      .slider-selection {
        background-color: $body-yellow;
        background-image: none;
      }
      .slider-track-high {
        background-image: none;
        background-color: $brand-2;
      }
      .slider-handle {
        background-color: $body-red;
        border-radius: 0px;
        background-image: none;
      }
    }
  }
}
