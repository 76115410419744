.noUi-tooltip {
    border: none;
    border-radius: 0;
    background: transparent;
}

.noUi-connect {
    box-shadow: none;
}

.noUi-handle {
    border-radius: 0;
    border-color: $brand-1;

    &:before, &:after {
        background: $brand-1;
    }
}

.noUi-target {
    border-radius: 0;
    border: none;
    box-shadow: none;
}

.noUi-value-sub, .noUi-value-large {
    margin-top: 5px;
    color: $brand-1;
    -webkit-font-smoothing: subpixel-antialiased;
}

.noUi-marker-horizontal {
    &.noUi-marker {
        width: 1px;
    }
}