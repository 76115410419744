// Spacer classes
//
// TODO: We should not need this at all!!!
// -----------------------------------------------------------------------------


.top10 {
  margin-top:10px;
}
.bott10 {
  margin-bottom:10px;
}
.prefix10 {
  margin-left:10px;
}
.suffix10 {
  margin-right:10px;
}

.top20 {
  margin-top:20px;
}
.bott20 {
  margin-bottom:20px;
}
.prefix20 {
  margin-left:20px;
}
.suffix20 {
  margin-right:20px;
}
