//TEXTS
//------------------------------------------------------------------------------

.title-box {
  border-bottom: 9px solid $body-yellow;
  background-color: $body-red;
  margin-bottom: 10px;
  h1 {
    font-family: $font-family-main;
    color:$body-white;
    margin-bottom:0px;
    padding-left:20px;
    line-height:70px;
    font-size:24px;
  }

  h2 {
    background-color: $body-yellow;
    display:inline-block;
    line-height: 50px;
    color:$brand-primary;
    margin-bottom:0px;
    padding-left:20px;
    padding-right: 30px;
  }

  h3, h4, h5, h6 {
    color: $body-red;
    font-family: $font-family-main-bold;
    margin:0px;
  }

  ul {
    background-color: $body-red;
    line-height: 26px;
    margin: 0px;
    label {
      background-color: $body-yellow;
      color: $brand-primary;
      min-width: 300px;
      padding-left: 20px;
      padding-top: 5px;
      font-size: 30px;
      line-height: 40px;
      margin: 0px;
      font-weight: bold;
    }
    li a {
      font-size: 16px;
      font-family: $font-family-main-bold;
      padding-left: 20px;
      padding-right: 20px;
      line-height: 34px;
      margin: 0px;
      border: none !important;
      color: $brand-1 !important;
      background-color: $body-white;
    }
    li.active a {
      background-color: $body-yellow;
      color: $brand-primary !important;
    }
    li a:hover {
      background-color: $body-yellow !important;
      color: $brand-primary !important;
    }
  }
  .nav-tabs {
    border: none;
  }
}
