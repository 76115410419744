//BUTTONS
//------------------------------------------------------------------------------
.btn {
  i {
    float: left;
  }
  text-align: center;
  color: $body-white;
  font-family: $font-family-main-bold;
  text-decoration: none;
  border-radius: 0px !important;
  border: none !important;
  transition: all .3s;

  &.btn-warning {
    &:hover {
      background-color: $brand-primary;
      color: $body-yellow;
    }
  }
}
.btn-lg {
  // line-height:52px;
  // font-size: 22px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  padding: 20px;

  i.fa {
    line-height:52px;
  }
}
.btn-md {
  line-height:48px;
  font-size:28px;
  i.fa {
    line-height:48px;
  }
}
.btn.btn-danger {
  background-color: $body-red;
  border-color: $body-red;
}

.btn.btn-default {
  background-color: $brand-1;
  border-color: $brand-1;
}

.btn.btn-primary {
  border-color: $body-blue;
  background-color: $body-blue;
}

.btn.btn-success {
  background-color: $body-green;
  border-color: $body-green;
  color: $brand-primary;
}

.btn.btn-warning {
  background-color: $body-yellow;
  border-color: $body-yellow;
  color: $brand-primary;
}

.btn.btn-danger:hover, .btn.btn-default:hover, .btn.btn-primary:hover, .btn.btn-success:hover {
  background-color: $brand-primary;
  color: $body-white;
}
