body.site-login {
    background-color: #f7f4ef;
}

.form-login {
    min-height: 350px;

    padding: 1em 1em 0em 1em;
    background-color: #fff;

    @include box-shadow(#888 0px 1px 2px);
    border: 1px #ccc solid;

    h1 {
        margin: 0;
        margin-bottom: 0.5em;
    }

    .links {
        margin: 0.5em 0;
        font-size: 0.9em;
        color: #888;
    }

}
