// NAVBAR
// -----------------------------------------------------------------------------
nav.navbar.navbar-main {
  background-color: $body-white;
  font-size:16px;
  border-bottom: 2px solid #eee;
  margin-bottom: 0;

  ul {
    li {
      border-left:1px solid $brand-1;
      border-right: none;
      a {
        font-family: $font-family-main-bold;
        color: $brand-1;
        width:140px;
        text-align:center;
      }

      a:hover {
        background-color:$body-white;
        color: $brand-primary;
      }
    }
    li:last-child {
      border-right:1px solid $brand-1;
    }
  }
  .navbar-brand {
    height: 70px;
    width: 70px;
    border-right: 2px solid #eee;
    border-bottom: none;
    border-top: none;
    border-left: none;
    transition: width .3s;

    a:hover {
      color: $brand-primary;
    }

    &:focus {
      outline: none;
    }

    .fa-bars {
      display: none;
    }

    .fa-times {
      display: inline-block;
    }
  }
  .navbar-logo {
    margin-left: 20px;
    padding: 15px 0;
    float: left;

    img {
      width: 40px;
      display: inline-block;
      margin-right: 15px;
      vertical-align: middle;
    }

    span  {
      display: inline-block;
      vertical-align: middle;
    }
  }
  
  .navbar-username {
    font-family: $font-family-main-bold;
    padding: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
    border-left: 2px solid #eee;
    text-overflow: ellipsis;
    max-width: 46%;
    white-space: nowrap;
    overflow: hidden;

    .fa-user {
      margin-right: 5px;
    }
  }

  .navbar-stop-client {
    background-color: white;
    border-left: 2px solid #eee;
    border-right: none;
    border-top: none;
    border-bottom: none;
    padding: 0;

    a {
      display: block;
      padding: 24px;
    }

    &:focus {
      outline: none;
    }
  }
}
