// Panels
// ----------------------------------------------------------------------------

$toggle-dimensions: 25px;
$toggle-thickness: 1px;

.panel {
  position: relative;
  border-bottom: 1px solid $brand-2;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;

  &.formulas {
    border: 0;
    padding-left: 5px;
  }

  .panel-heading {
    vertical-align: middle;

    .svg-inline--fa {
      margin-right: 5px;
    }

    .saving {
      text-align: right;

      @media (max-width: 767px) {
        text-align: left;
      }
    }

    .panel-toggle-icon {
      position: absolute;
      right: 0;
      top: 1.4rem;
      height: $toggle-dimensions;
      width: $toggle-dimensions;
      font-size: 3rem;
      transform: rotate(135deg);
      transition: transform 300ms;

      &.collapsed {
        transform: rotate(0deg);
      }

      &:before {
        content: '';
        position: absolute;
        left: calc( 50% - #{$toggle-thickness / 2} );
        top: 0;
        width: $toggle-thickness;
        height: $toggle-dimensions;
        background-color: $brand-primary;
      }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: calc( 50% - #{$toggle-thickness / 2} );
        height: $toggle-thickness;
        width: $toggle-dimensions;
        background-color: $brand-primary;
      }
    }
  }

  .savings-heading {
    padding: 5px;
    color: #919191;
  }

  .collapsed-savings {
    padding: $panel-heading-padding;

    @media (max-width: $screen-xs-min) {
      padding: 1.75rem 0;
    }
  }
}

.panel-group {
  .panel {
    &:first-child {
      border-top: 1px solid $brand-2;
    }

    &+.panel {
      &.total {
        border-top: 2px solid darken($brand-2, 5%);
        margin-top: 0;
      }
    }
  }

  .panel-heading {
    & + .panel-collapse {
      & >.panel-body {
        border-top: 0;
      }
    }
  }
}

.panels {
  .thumbnail {
    display: table;
    background-color: $brand-2;
    border: 2px solid $brand-3;
    color: $brand-3;
    width: 100%;
    height: 110px;
    font-size: 18px;
    text-align: center;
    text-decoration:none;
    span {
      display: table-cell;
      vertical-align: middle;
    }
    b {
      display: block;
      color: $brand-1;
    }
  }

  .thumbnail.active {
    background-color: $body-red;
    border: 2px solid $body-red;
    color: $body-white;
    b {
      color: $body-white;
    }
  }
  .thumbnail.required {
    background-color: $body-yellow;
    border: 2px solid $body-yellow;
    color: $brand-primary;
    b {
      color: $brand-primary;
    }
  }
}
