//
// Typography
// --------------------------------------------------


// Headings
// -------------------------

h1,h2,h3,h4,h5,h6 {
  font-family: $font-family-main-bold;
  color: $brand-primary;
  margin: 0px;
}

h1 {
  margin-bottom: 15px;
}

.foot-note {
  margin-top: 30px;
}

// Attribute values
.attribute-value {
    font-weight: bold;
}
