// Footer
// ----------------------------------------------------------------------------

.footer {
  position: fixed;
  z-index: 5;
  margin-top:20px;
  bottom: 0;
  width: 100%;
  height: 54px;
  background-color: $body-yellow;
  line-height:54px;
}
