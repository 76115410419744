//TABLES
//------------------------------------------------------------------------------
.table-striped>tbody>tr:nth-of-type(odd) {
  background-color: $brand-2;
}
.table {
  thead {
    background-color: $body-white;
  }
  th {
    font-family: $font-family-main-bold;
  }
  td:last-child {
    text-align: right;
  }
  tr,td,th {
    border: none !important;
  }
  color: $brand-1;
  tr.highlight {
    color: $body-white;
    background-color: $body-red !important;
  }
  .fa {
    margin-left: 20px;
  }
}
