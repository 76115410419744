// Progress bars
// ----------------------------------------------------------------------------

.progress {
  background-image: none;
  box-shadow: none;
  background-color: transparent;
  .progress-bar {
    box-shadow: none;
    //float: right;
  }
  label {
    //float: right;
    margin-left: 10px;
    font-weight: normal;
  }
  .progress-bar-default {
    background-color: $brand-primary;
    color: $brand-2;
  }
  .progress-bar-info {
    background-color: $body-blue;
    color: $brand-primary;
  }
  .progress-bar-success {
    background-color: $body-green;
    color: $brand-primary;
  }
  .progress-bar-warning {
    background-color: $body-yellow;
    color: $brand-primary;
  }
  .progress-bar-danger {
    background-color: $body-red;
    color: $body-white;
  }
  .progress-bar-primary {
    background-color: $body-blue;
    color: $body-white;
  }
}
