//
// Main layout
//
// Inspired by the "dashboard" BS3 demo
// --------------------------------------------------

body {
  color: $brand-primary;
  font-family: $font-family-main;
  padding-top: 110px;
  min-height: 100vh;
  background-color: $brand-4;

  &.sidebar-collapsed {

    nav.navbar.navbar-main {
      .navbar-brand {
        .fa-bars {
          display: inline-block;
        }

        .fa-times {
          display: none;
        }
      }
    }

    .sidebar {
      @media (max-width: $screen-xs-min) {
        left: -280px;
      }

      @media (min-width: $screen-xs-min) {
        width: 70px;
      }
  
      .nav-sidebar-subnav {
        @media (min-width: $screen-xs-min) {
          visibility: hidden;
          height: 0;
        }
      }
      .sidebar-heading {
        span {
          @media (min-width: $screen-xs-min) {
            visibility: hidden;
          }
        }
      }
    }
  }

  b {
    font-family: $font-family-main-bold;
  }
}

/*
 * Sidebar
 */

/* Hide for mobile, show later */
.sidebar {
  position: fixed;
  top: 71px;
  left: 0;
  height: calc( 100vh - 70px );
  width: 280px;
  z-index: 1000;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $body-white;
  border-right: 2px solid #eee;
  transition: width .3s, left .3s;
  -webkit-overflow-scrolling: touch;

  .sidebar-wrapper {
    // width: 100%;
    // height: calc( 100vh - 70px );
    // overflow-y: scroll;
    padding: 20px 0;
    // box-sizing: content-box;
  }
}

/*
 * Main content
 */

.main {
  // padding: 20px;
  padding-right: 20px;
  padding-left: 90px;
  transition: padding-left .3s;

  @media (max-width: $screen-xs-min) {
    padding-left: 20px;
  }
}
@media (min-width: 768px) {
  .main {
    padding-right: 40px;
    // padding-left: 300px;
    padding-left: 110px;
  }
}
.main .page-header {
  margin-top: 0;
}

/*
 * Top navigation
 * Hide default border to remove 1px line.
 */
.navbar-fixed-top {
  border: 0;
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

hr {
  margin-top: 10px;
  margin-bottom: 25px;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

