// Tooltips
// ----------------------------------------------------------------------------

div.tooltip {
  color: $brand-2;
  padding:10px;
  .tooltip-inner {
    background-color: $brand-primary;
    border-radius: 3px;
  }
}

div.tooltip.top .tooltip-arrow {
  border-top-color: $brand-primary;
}
div.tooltip.bottom .tooltip-arrow {
  border-bottom-color: $brand-primary;
}
