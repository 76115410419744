//
// Sidebar navigation
//
// Inspired by the "dashboard" BS3 demo
// --------------------------------------------------

.nav-sidebar {
  font-family: $font-family-main-bold;
  // margin-bottom: 20px;

  .nav-sidebar-subnav {
    visibility: visible;
    height: auto;

    li {
      a {
        white-space: nowrap;
        padding: 2px 65px;
      }
    }
  }

  .sidebar-heading {
    white-space: nowrap;

    svg {
      vertical-align: middle;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 1.6rem;
      margin-top: 3px;
      font-weight: 700;
      margin-left: 10px;
      visibility: visible;
    }
  }

  > li {
    margin-bottom: 10px;
  }

  li {
    &.active {
      > a {
        border-right: 3px solid $body-yellow;
      }
    }
  }

  /* Nested nav */
  ul {
    font-family: $font-family-main;
    li {
    }
  }
}
